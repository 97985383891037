import { defineStore } from 'pinia'
import { version } from '../../package.json'

export const useIndexStore = defineStore('index', {
    state: () => ({
        authenticated: false,
        locale: 'en',
        validationErrors: {},
        cleared: false,
        release: version
    }),
    persist: true,
    getters: {
        isAuthenticated(state) {
            return state.authenticated
        }
    },
    actions: {
        loggedIn () {
            this.authenticated = true
        },
        loggedOut () {
            this.authenticated = false
        },
        setValidationErrors (errors) {
            this.validationErrors = errors
        },
        setLocale (locale) {
            this.locale = locale
        },
        setCleared (cleared) {
            this.cleared = cleared
        },
        setRelease (version) {
            this.release = version
        }
    },
})
