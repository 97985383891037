// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import colors from 'vuetify/lib/util/colors'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const opts = {
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
    customProperties: true,
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors: {
                    link: '#34498e',
                    primary: colors.blue.darken4,
                    secondary: '#e7ebed',
                    tertiary: colors.blue.darken2,
                    error: '#b71c1c',
                }
            },
        },
    },
}

export default createVuetify(
    opts
)
