import { createApp } from 'vue'
import App from './App.vue'

// polyfill for .at()
if (!Array.prototype.at) {
    Array.prototype.at = function(index) {
        index = Math.trunc(index) || 0;
        if (index < 0) index += this.length;
        if (index < 0 || index >= this.length) return undefined;
        return this[index];
    };
}

if (!String.prototype.at) {
    String.prototype.at = function(index) {
        index = Math.trunc(index) || 0;
        if (index < 0) index += this.length;
        if (index < 0 || index >= this.length) return undefined;
        return this.charAt(index);
    };
}
// end polyfill

import 'material-design-icons-iconfont/dist/material-design-icons.css'

//Vue.config.productionTip = false

// You can also pass in the default options
// Vue.use(VueScrollTo, {
//   container: "body",
//   duration: 500,
//   easing: "ease",
//   offset: 0,
//   force: true,
//   cancelable: true,
//   onStart: false,
//   onDone: false,
//   onCancel: false,
//   x: false,
//   y: true
// })



const app = createApp(App)

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

import vuetify from './plugins/vuetify.js'
app.use(vuetify)

import { router } from './helpers';
app.use(router)

import {setupI18n} from './i18n.js'

const i18n = setupI18n({
    locale: import.meta.env.VITE_I18N_LOCALE || 'en',
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
    formatFallbackMessages: true,
    legacy: false
})

// global method for translations outside of components
window.translate = (key) => {
    return i18n.global.t(key)
}

app.use(i18n)


// import Vue3Material from 'vue3-material';
// app.use(Vue3Material)

import { VueReCaptcha } from 'vue-recaptcha-v3'
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY })


import { createHead } from '@unhead/vue'
const head = createHead()
app.use(head)

import VueGtag from "vue-gtag";
// enabled in CookieAcceptDecline or by reading LocalStorage in App.vue
app.use(VueGtag, {
    config: { id: "G-EC6RZBRT1R" },
    enabled: (import.meta.env.PROD && localStorage.getItem('vue-cookie-accept-decline-myPanel1') === 'accept'),
    bootstrap: true,
    params: {
        send_page_view: false
    }
}, router);

import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

//import "./vee-validate";

// Social Sharing
import VueSocialSharing from 'vue-social-sharing'
app.use(VueSocialSharing);

import CountryFlag from 'vue-country-flag-next'
app.use(CountryFlag);

// import * as VueGoogleMaps from "vue2-google-maps";
//
// app.use(VueGoogleMaps, {
//   load: {
//     key: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
//     libraries: "places" // necessary for places input
//   }
// });

import LoadScript from "vue-plugin-load-script";
app.use(LoadScript);

import vueVimeoPlayer from 'vue-vimeo-player'
app.use(vueVimeoPlayer)

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import packageJson from '../package.json';

Sentry.init({
  app,
    dsn: "https://529ccb3cfab240f7ae79580b82e72086@o153217.ingest.sentry.io/5725909",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "helios.co.uk", /^\//],
        }),
    ],
    logErrors: import.meta.env.PROD,
    debug: !import.meta.env.PROD,
    enabled: import.meta.env.PROD,
    environment: import.meta.env.PROD ? "production" : "development",
    release: "helios-vue-frontend@" + packageJson.version,
    ignoreErrors: [
        "ResizeObserver loop completed with undelivered notifications",
        "Kl.at is not a function",
        "SyntaxError",
        "Request failed with status code 401",
        "Unable to preload CSS for"
    ],
    beforeSend(event) {
    // Modify the event here
    if (event.exception && event.exception.values) {
      if (event.exception.values[0].value.includes('t._clickOutside is undefined')) {
        return null
      }
      else if (event.exception.values[0].value.includes("undefined is not an object (evaluating 't.$refs.content.contains')")) {
        return null
      }
    }

    return event;
  },
  tracesSampler: samplingContext => {
    // Examine provided context data (including parent decision, if any) along
    // with anything in the global namespace to compute the sample rate or
    // sampling decision for this transaction

    if (samplingContext.transactionContext.op === 'pageload' && samplingContext.transactionContext.name === '/') {
      // These are important - take a big sample
      return 0.5;
    } else {
      // Default sample rate
      return 0;
    }
  }
});

router.isReady().then(() => {
    app.mount('#app')
})
