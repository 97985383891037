<template>
    <VueCookieAcceptDecline
            :ref="'myPanel1'"
            :elementId="'myPanel1'"
            :debug="false"
            :position="'bottom-left'"
            :type="'floating'"
            :disableDecline="false"
            :transitionName="'slideFromBottom'"
            :showPostponeButton="false"
            @status="cookieStatus"
            @clicked-accept="cookieClickedAccept"
            @clicked-decline="cookieClickedDecline">

        <!-- Optional -->
        <div slot="postponeContent">
            &times;
        </div>

        <!-- Optional -->
        <div slot="message">
            We use cookies to ensure you get the best experience on our website. <router-link :to="{ name: 'cookie-policy'}">Learn More...</router-link>
        </div>

        <!-- Optional -->
        <div slot="declineContent">
            OPT OUT
        </div>

        <!-- Optional -->
        <div slot="acceptContent">
            OPT IN
        </div>
    </VueCookieAcceptDecline>
</template>

<script>
    import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

    export default {
        name: "CookieAcceptDecline",
        components: {
            'VueCookieAcceptDecline': VueCookieAcceptDecline
        },
        data () {
          return {
            status: null
          }
        },
        methods: {
            cookieStatus (status) {
                this.status = status
            },
            cookieClickedAccept () {
                this.status = 'accept'
                if (import.meta.env.PROD) {
                  this.$gtag.optIn()
                }
                this.$emit('set-status', this.status)
            },
            cookieClickedDecline () {
                this.status = 'decline'
                if (import.meta.env.PROD) {
                  this.$gtag.optOut()
                }
                this.$emit('set-status', this.status)
            },
            cookieRemovedCookie () {
                this.status = null
                this.$refs.myPanel1.init()
            },
            removeCookie () {
                this.$refs.myPanel1.removeCookie()
                this.$refs.myPanel1.init()
                this.$emit('set-status', this.status)
            }
        },
    }
</script>

<style scoped>

</style>
