<template>
  <v-container>
    <v-alert
        v-if="cleaned"
        border="start"
        type="warning"
        class="mb-3"
    >{{ $t('Some items have been removed that are no longer available; please review your basket.') }}</v-alert>
    <v-alert
        v-if="priceChanges"
        border="start"
        type="warning"
    >{{ $t('Some prices have been updated; please review your basket.') }}</v-alert>
    <v-card
            class="mx-auto product-card"
            elevation="0"
            max-width="600"
    >
        <v-card-title class="text-h5 text-primary text-left">{{ $t('headings.Basket') }}</v-card-title>
        <v-card-text>
          <div class="d-block d-sm-none">

            <v-alert
                v-if="containsCanBeFoilWrapped"
                border="start"
                color="info"
                icon="mdi-information"
                class="mt-3 mb-6"
                density="compact"
            >
              <div class="d-flex flex-row align-center justify-space-between">
                <p>{{ $t('headings.Foil wrap remedies, kits, dispensers and Sanum products for protection') }}?</p>
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn
                        variant="tonal"
                        v-bind="props"
                        @click="foilWrapDialog = true"
                        class="mb-3 ml-2"
                        ref="foilWrap"
                    >
                      {{ $t('buttons.Foil wrap') }}?
                    </v-btn>
                  </template>
                  <span>{{ $t('Click to change foil wrap remedies option') }}</span>
                </v-tooltip>
              </div>
            </v-alert>



            <v-btn block color="secondary" @click="$emit('close-drawer')" class="mb-6"><v-icon left>mdi-chevron-left</v-icon>{{ $t('buttons.Continue shopping') }}</v-btn>
            <v-btn block color="primary" @click="navigateToCheckout">{{ $t('links.Checkout') }}<v-icon right>mdi-chevron-right</v-icon></v-btn>



          </div>

          <div class="d-none d-sm-block mb-6">

            <v-alert
                v-if="containsCanBeFoilWrapped"
                border="start"
                color="info"
                icon="mdi-information"
                class="mt-3 mb-6"
                density="compact"
            >
              <div class="d-flex flex-row align-center justify-space-between">
                <p>{{ $t('headings.Foil wrap remedies, kits, dispensers and Sanum products for protection') }}?</p>
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn
                        variant="tonal"
                        v-bind="props"
                        @click="foilWrapDialog = true"
                        class="mb-3 ml-2"
                    >
                      {{ $t('buttons.Foil wrap') }}?
                    </v-btn>
                  </template>
                  <span>{{ $t('Click to change foil wrap option') }}</span>
                </v-tooltip>
              </div>
            </v-alert>

            <div class="mt-6 d-none d-sm-flex justify-space-between flex-wrap">
              <v-btn :block="isBlock" color="secondary" @click="$emit('close-drawer')" class="mb-6"><v-icon left>mdi-chevron-left</v-icon>{{ $t('buttons.Continue shopping') }}</v-btn>
              <v-btn v-if="basketProducts.length > 0" :block="isBlock" ripple color="primary" @click="navigateToCheckout">{{ $t('links.Checkout') }}<v-icon right>mdi-chevron-right</v-icon></v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="text--primary text-left">
            <div v-if="basketProducts.length > 0">
              <v-list two-line>
                <div v-for="(group, groupIndex) in productGroups" :key="'group-' + groupIndex">
                  <div v-if="group.length > 1">
                    <v-list-group
                        no-action

                    >
                      <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                        >
                          <div width="60px" class="mr-2" v-if="group[0].path">
                            <v-img
                                contain
                                height="60px"
                                width="60px"
                                :src="imagesHost + '/assets/product-basket/' + group[0].path"
                            />
                          </div>

                          <v-list-item-title>{{ groupIndex }}</v-list-item-title>
                        </v-list-item>


                      </template>

                      <template v-for="(product, index) in group" :key="product.sku">

                        <v-list-item>


                          <v-list-item-title class="text-subtitle-2"><router-link :to="{ name: 'product', params: {slug: product.slug} }">{{ product.name }}</router-link></v-list-item-title>
                          <v-list-item-subtitle class="body-2">£{{ product.price }}</v-list-item-subtitle>
                          <v-list-item-subtitle class="body-2" v-if="product.form">Form: {{ product.form.name }}</v-list-item-subtitle>
                          <v-list-item-subtitle class="body-2" v-if="product.size">Size: {{ product.size.quantity }}</v-list-item-subtitle>
                          <v-list-item-subtitle class="body-2" v-if="product.potency">Potency: {{ product.potency.level }}</v-list-item-subtitle>





                          <template v-slot:append>
                            <div class="d-none d-sm-flex justify-space-between flex-column align-center">
                              <v-btn class="mb-3" text size="small" variant="flat" @click="editRemedy(product.basketIndex)">
                                {{ $t('links.Edit') }}
                                <v-icon
                                    size="x-large"
                                    color="grey lighten-1"
                                >mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                  text
                                  size="small"
                                  variant="flat"
                                  @click="removeFromBasket(product, product.basketIndex)"
                              >
                                {{ $t('links.Remove') }}
                                <v-icon
                                    size="x-large"
                                    color="grey lighten-1"
                                >mdi-trash-can</v-icon>
                              </v-btn>
                            </div>
                            <div class="d-flex d-sm-none justify-space-between flex-column align-center">
                              <v-btn class="mb-1" variant="flat" size="small" @click="editRemedy(product.basketIndex)">
                                <v-icon
                                    size="large"
                                    color="grey lighten-1"
                                >mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                  variant="flat" size="small"
                                  @click="removeFromBasket(product, product.basketIndex)"
                              >
                                <v-icon
                                    size="large"
                                    color="grey lighten-1"
                                >mdi-trash-can</v-icon>
                              </v-btn>
                            </div>
                          </template>

                        </v-list-item>
                        <v-divider
                            v-if="index + 1 < group.length"
                            :key="'remedy-' + index"
                        ></v-divider>
                      </template>



                    </v-list-group>
                    <v-divider
                        :key="'group-' + groupIndex"
                    ></v-divider>
                  </div>
                  <div v-else>
                    <template v-for="(product, index) in group" :key="product.sku">

                      <v-list-item>


                        <v-list-item-title class="text-subtitle-2"><router-link :to="{ name: 'product', params: {slug: product.slug} }">{{ product.name }}</router-link></v-list-item-title>
                        <v-list-item-subtitle class="body-2">£{{ product.price }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="body-2" v-if="product.form">Form: {{ product.form.name }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="body-2" v-if="product.size">Size: {{ product.size.quantity }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="body-2" v-if="product.potency">Potency: {{ product.potency.level }}</v-list-item-subtitle>
                        <div class="d-flex d-sm-none justify-space-between">
                          <div class="d-block mr-0 mb-3 mt-3">
                            <div style="width: 150px" class="d-block d-flex justify-space-around" v-if="!product.form && !isLoading(groupIndex)">

                              <v-btn icon @click="decrement(groupIndex)" size="small" class="align-self-center">
                                <v-icon
                                    color="primary"

                                >
                                  mdi-minus
                                </v-icon>
                              </v-btn>
                              <div
                                  class="display-1 font-weight-light text-right mr-0 ml-0 mt-0 align-self-center"
                                  v-text="product.quantity"
                              ></div>
                              <v-btn icon @click="increment(groupIndex)" size="small" class="align-self-center">
                                <v-icon
                                    color="primary"

                                >
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </div>
                            <div style="width: 150px" v-if="isLoading(groupIndex)" class="d-block d-flex justify-center">
                              <v-btn

                                  :loading="isLoading(groupIndex)"
                                  :disabled="isLoading(groupIndex)"
                                  fab
                                  small
                              >
                              </v-btn>
                            </div>
                          </div>
                          <!--                                        <v-btn-->
                          <!--                                                class="mt-3 d-none d-sm-block"-->
                          <!--                                                text-->
                          <!--                                                @click="removeFromBasket(product, index)"-->
                          <!--                                        >-->
                          <!--                                            {{ $t('links.Remove') }}-->
                          <!--                                            <v-icon-->
                          <!--                                                    large-->
                          <!--                                                    color="grey lighten-1"-->
                          <!--                                            >mdi-trash-can</v-icon>-->
                          <!--                                        </v-btn>-->
                          <!--                                      <v-btn-->
                          <!--                                          class="mt-3 d-flex d-sm-none"-->
                          <!--                                          icon-->
                          <!--                                          @click="removeFromBasket(product, index)"-->
                          <!--                                      >-->
                          <!--                                        <v-icon-->
                          <!--                                            large-->
                          <!--                                            color="grey lighten-1"-->
                          <!--                                        >mdi-trash-can</v-icon>-->
                          <!--                                      </v-btn>-->
                        </div>




                        <!--                                <v-list-item-action class="d-none d-sm-flex justify-end flex-column">-->
                        <!--                                    <div style="width: 150px" class="d-block mb-3 mt-6">-->
                        <!--                                        <div class="d-block d-flex justify-space-between" v-if="!product.form && !isLoading(groupIndex)">-->

                        <!--                                            <v-btn icon flat @click="decrement(groupIndex)" class="align-self-center">-->
                        <!--                                                <v-icon-->
                        <!--                                                        color="primary"-->

                        <!--                                                >-->
                        <!--                                                    mdi-minus-->
                        <!--                                                </v-icon>-->
                        <!--                                            </v-btn>-->
                        <!--                                            <div-->
                        <!--                                                    class="display-1 font-weight-light text-right mr-6 ml-6 mt-0 align-self-center"-->
                        <!--                                                    v-text="product.quantity"-->
                        <!--                                            ></div>-->
                        <!--                                            <v-btn icon flat @click="increment(groupIndex)" class="align-self-center">-->
                        <!--                                                <v-icon-->
                        <!--                                                        color="primary"-->

                        <!--                                                >-->
                        <!--                                                    mdi-plus-->
                        <!--                                                </v-icon>-->
                        <!--                                            </v-btn>-->
                        <!--                                        </div>-->
                        <!--                                        <div v-if="isLoading(groupIndex)" class="d-block d-flex justify-center">-->
                        <!--                                            <v-btn-->

                        <!--                                                   :loading="isLoading(groupIndex)"-->
                        <!--                                                   :disabled="isLoading(groupIndex)"-->
                        <!--                                                   fab-->
                        <!--                                                   small-->
                        <!--                                            >-->
                        <!--                                            </v-btn>-->
                        <!--                                        </div>-->

                        <!--                                    </div>-->
                        <!--                                    <v-btn-->
                        <!--                                            text-->
                        <!--                                            flat-->
                        <!--                                            @click="removeFromBasket(product)"-->
                        <!--                                    >-->
                        <!--                                        {{ $t('links.Remove') }}-->
                        <!--                                        <v-icon-->
                        <!--                                                large-->
                        <!--                                                color="grey lighten-1"-->
                        <!--                                        >mdi-trash-can</v-icon>-->
                        <!--                                    </v-btn>-->
                        <!--                                </v-list-item-action>-->

                        <template v-slot:prepend>
                          <div width="60px" class="mr-2" v-if="product.path">
                            <v-img
                                contain
                                width="60px"
                                height="60px"
                                :src="imagesHost + '/assets/product-basket/' + product.path"
                            />
                          </div>
                        </template>

                        <template v-slot:append>
                          <div class="d-none d-sm-flex justify-end flex-column">
                            <div style="width: 150px" class="d-block mb-3 mt-6">
                              <div class="d-block d-flex justify-space-between" v-if="!product.form && !isLoading(groupIndex)">

                                <v-btn icon flat @click="decrement(groupIndex)" class="align-self-center">
                                  <v-icon
                                      color="primary"

                                  >
                                    mdi-minus
                                  </v-icon>
                                </v-btn>
                                <div
                                    class="text-h4 font-weight-light text-right mr-6 ml-6 mt-0 align-self-center"
                                    v-text="product.quantity"
                                ></div>
                                <v-btn icon flat @click="increment(groupIndex)" class="align-self-center">
                                  <v-icon
                                      color="primary"

                                  >
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </div>
                              <div v-if="isLoading(groupIndex)" class="d-block d-flex justify-center">
                                <v-btn

                                    :loading="isLoading(groupIndex)"
                                    :disabled="isLoading(groupIndex)"
                                    fab
                                    small
                                >
                                </v-btn>
                              </div>

                            </div>
                            <div class="d-flex flex-column justify-space-between flex-column align-end">
                              <v-btn v-if="product.is_remedy" class="mb-3" text size="small" variant="flat" @click="editRemedy(product.basketIndex)">
                                {{ $t('links.Edit') }}
                                <v-icon
                                    size="x-large"
                                    color="grey lighten-1"
                                >mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                  text
                                  size="small"
                                  variant="flat"
                                  @click="removeFromBasket(product, product.basketIndex)"
                              >
                                {{ $t('links.Remove') }}
                                <v-icon
                                    size="x-large"
                                    color="grey lighten-1"
                                >mdi-trash-can</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="d-flex d-sm-none justify-end flex-column">
                            <v-btn v-if="product.is_remedy" class="mb-1" icon="mdi-pencil" text size="small" variant="flat" @click="editRemedy(product.basketIndex)">
                              <v-icon
                                  size="large"
                                  color="grey lighten-1"
                              >mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                size="small"
                                variant="flat"
                                @click="removeFromBasket(product, product.basketIndex)"
                            >
                              <v-icon
                                  size="large"
                                  color="grey lighten-1"
                              >mdi-trash-can</v-icon>
                            </v-btn>
                          </div>
                        </template>

                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                  </div>
                </div>

              </v-list>
              <div v-if="basketProducts.length > 0">

                <p class="total text-subtitle-2 text-right mt-6" v-if="productsTotal">{{ $t('Sub total') }} <span style="display: inline-block; width: 70px;">£{{ productsTotal.toFixed(2) }}</span></p>
              </div>

              <div v-if="Object.keys(groupDiscounts).length > 0">
                <v-divider></v-divider>
                <h2 class="mt-6 text-subtitle-1">{{ $t('Group discounts') }}</h2>
                <v-list two-line >
                    <v-list-item
                        v-for="(group, index) in groupDiscounts"
                        :key="'group-' + group.id"
                        class="pl-0 pr-0 mb-2"
                    >

                      <v-list-item-title>{{ group.name }}</v-list-item-title>
                      <v-list-item-subtitle class="pb-2" style="opacity: 1"><router-link class="text-red" v-if="group.slug" :to="{ name: 'discount', params: {slug: group.slug} }">{{ discountGroupText(group) }}</router-link><span v-else>{{ discountGroupText(group) }}</span></v-list-item-subtitle>
                      <v-list-item-subtitle><span class='text-decoration-line-through'>£{{group.discountable.toFixed(2)}}</span> <span>£{{ discountedTotal(group) }}</span> You saved £{{ group.discount_total }}</v-list-item-subtitle>


                    </v-list-item>
                    <v-list-item class="pr-0">
                      <p class="total text-subtitle-2 text-right mt-6"  v-if="groupDiscountTotal > 0">-£{{ groupDiscountTotal.toFixed(2) }}</p>
                    </v-list-item>
                </v-list>
              </div>

              <div v-if="foilWrapped">
                <v-divider></v-divider>
                <p class="total text-subtitle-2 text-right mt-6">{{ $t('Foil wrap') }}<span style="display: inline-block; width: 70px;" ref="foilWrapTotal"> £{{ foilWrapTotal.toFixed(2) }}</span></p>
              </div>

              <div v-if="userDiscount > 0">
                <v-divider></v-divider>
                <p class="total text-subtitle-2 text-right mt-6">{{ $t('Practitioner / trade discount') }} - {{ userDiscountRate }}% <span style="display: inline-block; width: 70px;">-£{{ userDiscount }}</span></p>
              </div>

              <div v-if="vatDeducted > 0">
                <v-divider></v-divider>
                <p class="total text-subtitle-2 text-right mt-6">{{ $t('headings.VAT deducted') }} <span style="display: inline-block; width: 70px;">-£{{ vatDeducted }}</span></p>
              </div>

              <div v-if="bulkDiscount && bulkDiscount.discount > 0">
                <v-divider></v-divider>
                <p class="total text-subtitle-2 text-right mt-6">{{ $t('Bulk discounts') }} - {{ bulkDiscount.rate }}% <span style="display: inline-block; width: 70px;">-£{{ bulkDiscount.discount.toFixed(2) }}</span></p>
              </div>

              <div v-if="basketProducts.length > 0">
                <v-divider></v-divider>
                <p class="total text-subtitle-2 text-right mt-6" v-if="calculatedTotal">{{ $t('Total') }} <span style="display: inline-block; width: 70px;">£{{ calculatedTotal.toFixed(2) }}</span></p>

              </div>
            </div>

            <div v-else>{{ $t('Your basket is empty.') }} <router-link :to="{ name: 'login' }">{{ $t('Log back in to retrieve your saved basket.') }}</router-link></div>

<!--            <div v-if="basketProducts.length > 0">-->

<!--                <p class="total text-subtitle-2 text-right mt-6" v-if="basketTotal">{{ $t('Sub total') }} <span style="display: inline-block; width: 70px;">£{{ basketTotal }}</span></p>-->
<!--            </div>-->

<!--            <div v-if="Object.keys(basketDiscountGroups).length > 0">-->
<!--                <v-divider></v-divider>-->
<!--                <h2 class="mt-6">{{ $t('Group discounts') }}</h2>-->
<!--                <v-list two-line >-->

<!--                    <template v-for="(group, index) in basketDiscountGroups" v-bind:key="group.id">-->
<!--                        <v-list-item>-->
<!--                            -->
<!--                                <v-list-item-title>{{ group.name }}</v-list-item-title>-->
<!--                                <v-list-item-subtitle>£{{ group.discount_total }}</v-list-item-subtitle>-->
<!--                                <p class="total text-subtitle-2 text-right mt-6"  v-if="groupDiscountTotal > 0">-£{{ groupDiscountTotal }}</p>-->
<!--                            -->
<!--                        </v-list-item>-->
<!--                    </template>-->
<!--                </v-list>-->
<!--            </div>-->

<!--            <div v-if="foilWrapped">-->
<!--                <v-divider></v-divider>-->
<!--                <p class="total text-subtitle-2 text-right mt-6">{{ $t('Foil wrap remedies') }}<span style="display: inline-block; width: 70px;"> £{{ foilWrapTotal }}</span></p>-->
<!--            </div>-->

<!--            <div v-if="userDiscount > 0">-->
<!--                <v-divider></v-divider>-->
<!--                <p class="total text-subtitle-2 text-right mt-6">{{ $t('Practitioner / trade discount') }} - {{ userDiscountRate }}% <span style="display: inline-block; width: 70px;">-£{{ userDiscount }}</span></p>-->
<!--            </div>-->

<!--            <div v-if="bulkDiscount.discount > 0">-->
<!--                <v-divider></v-divider>-->
<!--                <p class="total text-subtitle-2 text-right mt-6">{{ $t('Bulk discounts') }} - {{ bulkDiscount.rate }}% <span style="display: inline-block; width: 70px;">-£{{ bulkDiscount.discount }}</span></p>-->
<!--            </div>-->

<!--            <div v-if="basketProducts.length > 0">-->
<!--                <v-divider></v-divider>-->
<!--                <p class="total text-subtitle-2 text-right mt-6" v-if="grandTotal">{{ $t('Total') }} <span style="display: inline-block; width: 70px;">£{{ grandTotal }}</span></p>-->

<!--            </div>-->

<!--            <v-dialog-->
<!--                    v-model="foilWrapDialog"-->
<!--                    width="500"-->
<!--            >-->

<!--                <v-card>-->
<!--                    <v-card-title class="text-h5 grey lighten-2">-->
<!--                        {{ $t('headings.Foil wrap remedies, kits and dispensers for protection') }}?-->
<!--                    </v-card-title>-->

<!--                    <v-card-text>-->
<!--                        <p class="text-body-1 mt-6">-->
<!--                            £2 for up to 6 remedies<br>-->
<!--                            £3 for up to 10<br>-->
<!--                            then £1 for each additional 5.<br>-->
<!--                        </p>-->
<!--                    </v-card-text>-->

<!--                    <v-divider></v-divider>-->

<!--                    <v-card-actions class="d-flex justify-space-between">-->

<!--                        <v-btn-->
<!--                                color="primary"-->
<!--                                text-->
<!--                                @click="removeFoilWrap"-->
<!--                        >-->
<!--                            No thanks-->
<!--                        </v-btn>-->
<!--                        <v-btn-->
<!--                                color="success"-->
<!--                                dark-->
<!--                                @click="addFoilWrap"-->
<!--                        >-->
<!--                            Yes please-->
<!--                        </v-btn>-->
<!--                    </v-card-actions>-->
<!--                </v-card>-->
<!--            </v-dialog>-->

<!--            <div class="d-block d-sm-none">-->

<!--                <v-tooltip bottom v-if="containsRemedies">-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-btn-->
<!--                                color="red lighten-2"-->
<!--                                dark-->
<!--                                block-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="foilWrapDialog = true"-->
<!--                                class="mb-3"-->
<!--                        >-->
<!--                            {{ $t('buttons.Foil wrap') }}?-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <span>{{ $t('Click to change foil wrap remedies option') }}</span>-->
<!--                </v-tooltip>-->



<!--                    <v-btn block @click="$emit('close-drawer')" class="mb-6">{{ $t('buttons.Continue shopping') }}</v-btn>-->
<!--                    <v-btn block color="primary" @click="navigateToCheckout">{{ $t('links.Checkout') }}<v-icon right>mdi-chevron-right</v-icon></v-btn>-->


<!--            </div>-->

<!--            <div class="d-none d-sm-flex justify-space-between flex-wrap" v-intersect="onIntersect">-->

<!--                <v-tooltip bottom v-if="containsRemedies">-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-btn-->
<!--                                color="red lighten-2"-->
<!--                                dark-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="foilWrapDialog = true"-->
<!--                                class="mb-6"-->
<!--                                :block="isBlock"-->
<!--                        >-->
<!--                            {{ $t('buttons.Foil wrap') }}?-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <span>{{ $t('Click to change foil wrap remedies option') }}</span>-->
<!--                </v-tooltip>-->


<!--                <v-btn :block="isBlock" @click="$emit('close-drawer')" class="mb-6">{{ $t('buttons.Continue shopping') }}</v-btn>-->
<!--                <v-btn v-if="basketProducts.length > 0" :block="isBlock" tile rounded ripple color="primary" @click="navigateToCheckout">{{ $t('links.Checkout') }}<v-icon right>mdi-chevron-right</v-icon></v-btn>-->

<!--            </div>-->
            <v-dialog
                    v-model="foilWrapDialog"
                    width="500"
            >

                <v-card>
                    <v-card-title class="text-h5 grey lighten-2" style="white-space: normal">
                        {{ $t('headings.Foil wrap remedies, kits, dispensers and Sanum products for protection') }}?
                    </v-card-title>
                    <v-card-text>
                        <p class="text-body-1">
                          {{ $t('{price} for up to {count} items', {price: '£3', count: '6'}) }}<br>
                          {{ $t('then {price} for each additional {count}.', {price: '£2', count: '5'}) }}<br>
                        </p>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="d-flex justify-space-between">

                        <v-btn
                            variant="tonal"
                            color="primary"
                            @click="removeFoilWrap"
                            ref="foilWrapNo"
                        >
                            No thanks
                        </v-btn>
                        <v-btn
                            variant="flat"
                            color="primary"
                            @click="addFoilWrap"
                            ref="foilWrapYes"
                        >
                            Yes please
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <div class="d-block d-sm-none">

                    <v-btn block color="secondary" @click="$emit('close-drawer')" class="mb-6"><v-icon left>mdi-chevron-left</v-icon>{{ $t('buttons.Continue shopping') }}</v-btn>
                    <v-btn block color="primary" @click="navigateToCheckout">{{ $t('links.Checkout') }}<v-icon right>mdi-chevron-right</v-icon></v-btn>

            </div>

            <div class="mt-6 d-none d-sm-flex justify-space-between flex-wrap" v-intersect="onIntersect">

                <v-btn :block="isBlock" color="secondary" @click="$emit('close-drawer')" class="mb-6"><v-icon left>mdi-chevron-left</v-icon>{{ $t('buttons.Continue shopping') }}</v-btn>
                <v-btn v-if="basketProducts.length > 0" :block="isBlock" ripple color="primary" @click="navigateToCheckout">{{ $t('links.Checkout') }}<v-icon right>mdi-chevron-right</v-icon></v-btn>

            </div>

            <div>
              <v-btn :block="isBlock" color="secondary" @click="clearBasketModal = true" class="mt-6"><v-icon left>mdi-trash-can-outline</v-icon>{{ $t('buttons.Clear basket') }}</v-btn>
              <v-dialog v-model="clearBasketModal" width="500px">
                <v-card>
                  <v-card-title>{{ $t('buttons.Clear basket') }}</v-card-title>
                  <v-card-text>{{ $t('Are you sure you want to remove all the items in your basket?') }}</v-card-text>
                  <v-card-actions class="d-flex justify-space-between">
                    <v-btn variant="flat" color="secondary" @click="clearBasketModal = false">{{ $t('buttons.No') }}</v-btn>
                    <v-btn variant="flat" color="primary" @click="clearBasket" :disabled="loading"
                           :loading="loading">{{ $t('buttons.Yes') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>



        </v-card-text>
    </v-card>

    <v-dialog v-model="editRemedyDialog" width="500">
      <v-card>
        <v-card-title>Edit remedy: {{product.name}}</v-card-title>
        <v-card-text>
          <Form ref="editRemedyForm" @submit.prevent="updateRemedy" @invalid-submit="onInvalidSubmit" :validation-schema="remedySchema" v-slot="{ errors, setFieldError, setFieldValue }">
            <Field v-model="product.form" name="form" v-slot="{ field, errors, errorMessage }">
              <v-select
                  :items="product.forms"
                  v-bind="field"
                  v-model="product.form"
                  item-title="name"
                  item-value="id"
                  :label="$t('fields.Form')"
                  @update:modelValue="chooseForm($event)"
                  :menu-props="{ 'maxHeight': '1000px' }"
                  :error-messages="errors[0]"
                  class="mb-3"
              ></v-select>
            </Field>
            <Field v-model="product.size" name="size" v-slot="{ field, errors, errorMessage }">
              <v-select
                  :items="sizes"
                  v-bind="field"
                  v-model="product.size"
                  item-title="label"
                  item-value="id"
                  :label="$t('fields.Size')"
                  @update:modelValue="chooseSize($event)"
                  :menu-props="{ 'maxHeight': '1000px' }"
                  no-data-text="Please select a remedy form first"
                  :error-messages="errors[0]"
                  class="mb-3"
              ></v-select>
            </Field>
            <Field v-model="product.potency" name="potency" v-slot="{ field, errors, errorMessage }">
              <v-select
                  :items="potencies"
                  v-bind="field"
                  v-model="product.potency"
                  item-title="level"
                  item-value="id"
                  :label="$t('fields.Potency')"
                  no-data-text="Please select a remedy form first"
                  :error-messages="errors[0]"
                  class="mb-3"
              ></v-select>
            </Field>

          </Form>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn variant="flat" color="secondary" @click="editRemedyDialog = false">{{ $t('buttons.Close') }}</v-btn>
          <v-btn variant="flat" elevation="0" color="primary" @click="updateRemedy()">
            <v-icon left>mdi-cart-check</v-icon> {{ $t('fields.Update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!--    <v-fab-transition>-->
<!--      <v-btn class="d-none d-sm-flex" v-if="!isIntersecting" fab bottom right absolute style="margin-bottom: 50px" color="primary" @click="scrollDrawer"><v-icon>mdi-chevron-down</v-icon></v-btn>-->
<!--    </v-fab-transition>-->

  </v-container>
</template>

<script>
    //import { store } from '../store';
    import { mapActions, mapState } from 'pinia'
    import {useIndexStore} from "../stores";
    import {useBasketStore} from "../stores/basket";
    import {useAccountStore} from "../stores/account";
    import {basketService} from "../services";
    import {router} from "../helpers";
    import axios from "axios";
    import { Field, Form } from 'vee-validate';
    import * as yup from "yup";
    import Socials from "./Socials.vue";

    export default {
        name: "BasketManage",
        props: ['drawer', 'basketDrawerContent'],
        // beforeRouteUpdate (to, from, next) {
        //     let locale = this.locale;
        //
        //     if(locale !== null && to.path.startsWith('/' + locale) === false) {
        //         this.$router.push('/' + locale + to.path)
        //     } else {
        //         next();
        //     }
        // },
        components: {
          Form,
          Field
        },
        data () {
            return {
                attrs: '',
                foilWrapDialog: false,
                loading: false,
                isIntersecting: false,
                editRemedyDialog: false,
                product: null,
                sizes: [],
                potencies: [],
                form: null,
                size: null,
                potency: null,
                price: null,
                clearBasketModal: false
            }
        },
        computed: {
            ...mapState(useIndexStore, ['locale']),
            ...mapState(useAccountStore, ['userDiscountRate']),
            ...mapState(useBasketStore, [
              'foilWrapped',
              'foilWrapTotal',
              'containsRemedies',
              'containsCanBeFoilWrapped',
              'cleaned',
              'priceChanges',
              'userDiscount',
              'bulkDiscount',
              'basketProducts',
              'productGroups',
              'grandTotal',
              'groupDiscountTotal',
              'productsTotal',
              'calculatedTotal',
              'groupDiscounts',
              'discountGroupIds',
              'vatDeducted',
            ]),
            imagesHost() {
              return import.meta.env.VITE_IMAGES_HOST;
            },
            isBlock () {
              if (import.meta.env.NODE_ENV !== 'test') {
                if (window.innerWidth < 960) {
                  return true
                }
                else {
                  return false
                }
              }
              else {
                return true
              }
            },
            remedySchema() {
              return yup.object({
                form: yup.string().required().label(this.$t('fields.Form')),
                size: yup.string().required().label(this.$t('fields.Size')),
                potency: yup.string().required().label(this.$t('fields.Potency')),
              })
            },
        },
        mounted () {
            if (import.meta.env.NODE_ENV !== 'test') {
              document.getElementById("scrolling-content").scrollTop = 0
            }
        },
        // watch: {
        //     drawer(val) {
        //         if (val && this.containsRemedies && this.hasFoilWrapped === false) {
        //             this.foilWrapDialog = true
        //         }
        //     }
        // },
        methods: {
            ...mapActions(useBasketStore, ['remove', 'clear', 'update', 'updateRemedyOptions', 'setFoilWrapped']),
            discountedTotal(group) {
              return (group.discountable - group.discount_total).toFixed(2)
            },
            discountGroupText(discountGroup) {

              if (discountGroup.products_count > 1) {
                return this.$t("links.Buy any {quantity} get {discount} off", {
                  quantity: discountGroup.qualifying_quantity,
                  discount: discountGroup.discount_percent + '%'
                })
              } else if (discountGroup.products_count === 1 && discountGroup.qualifying_quantity > 1) {
                return this.$t("Buy {quantity} or more get {discount} off", {
                  quantity: discountGroup.qualifying_quantity,
                  discount: discountGroup.discount_percent + '%'
                })
              } else if (discountGroup.products_count === 1 && discountGroup.qualifying_quantity === 1) {
                return this.$t("{discount} off", {
                  quantity: discountGroup.qualifying_quantity,
                  discount: discountGroup.discount_percent + '%'
                })
              }

              return null
            },
            onInvalidSubmit({ values, errors, results }) {
              console.log(values); // current form values
              console.log(errors); // a map of field names and their first error message
              console.log(results); // a detailed map of field names and their validation results

              this.snackbar = true
              this.snackColor = 'error'
              this.snackText = this.$t('Please check the form for errors')
            },
            editRemedy(index) {
                let editedProduct = this.basketProducts[index]

                axios
                    .get(import.meta.env.VITE_API_HOST + '/api/products/' + editedProduct.slug)
                    .then(response => {
                      this.product = response.data.data
                      this.product.form = editedProduct.form.id
                      this.product.size = editedProduct.size.id
                      this.product.potency = editedProduct.potency.id
                      this.product.basket_index = index

                      let forms = this.product.forms

                      let formSelected = forms.filter(
                          function(forms){ return forms.id === editedProduct.form.id }
                      )[0];

                      // add label with price to json
                      let list = formSelected.sizes.map(function(item) {
                        return {id: item.id, form: item.form, quantity: item.quantity, price: item.price, weight: item.weight, label: item.quantity + ' - £' + item.price};
                      });

                      this.sizes = list

                      this.potencies = formSelected.potencies

                      this.editRemedyDialog = true
                    })
                    .catch(error => {
                      console.log(error)
                    })

            },
            async updateRemedy() {
              let product = this.product
              const {form, size, potency} = product
              console.log(product)

              let formModel = null
              let sizeModel = null
              let potencyModel = null

              let validate = await this.$refs.editRemedyForm.validate();

              if (!validate.valid) {
                this.loading = false
              }

              if (validate.valid) {
                let formsData = product.forms

                let formsFiltered = formsData.filter(
                    function (formsData) {
                      return formsData.id === form
                    }
                )[0];

                formModel = {
                  id: formsFiltered.id,
                  name: formsFiltered.name
                }

                let sizesData = this.sizes
                let sizesFiltered = sizesData.filter(
                    function (sizesData) {
                      return sizesData.id === size
                    }
                )[0];

                sizeModel = {
                  id: sizesFiltered.id,
                  quantity: sizesFiltered.quantity,
                  price: sizesFiltered.price,
                  weight: sizesFiltered.weight
                }

                let potenciesData = this.potencies

                let potenciesFiltered = potenciesData.filter(
                    function (potenciesData) {
                      return potenciesData.id === potency
                    }
                )[0];

                potencyModel = {
                  id: potenciesFiltered.id,
                  level: potenciesFiltered.level
                }

                this.updateRemedyOptions({product, formModel, sizeModel, potencyModel}).then(() => {
                  this.editRemedyDialog = false
                  // let message = 'updated'
                  // this.setBasketPopover({message}, this.$t)
                  // setTimeout(function () {
                  //   let store = useBasketStore()
                  //   store.$patch({popover: false, popoverMessage: ''})
                  // }, 3000)

                })
              }
            },
            chooseForm(id) {

              this.product.size = null
              this.product.potency = null
              let forms = this.product.forms

              let formSelected = forms.filter(
                  function(forms){ return forms.id === id }
              )[0];

              // add label with price to json
              let list = formSelected.sizes.map(function(item) {
                return {id: item.id, form: item.form, quantity: item.quantity, price: item.price, weight: item.weight, label: item.quantity + ' - £' + item.price};
              });

              this.sizes = list

              this.potencies = formSelected.potencies

              if (this.potencies.length === 1) {
                this.product.potency = this.potencies[0].id
              }

              // if (id === 8 && this.product.is_insoluble) {
              //     let levels = ['3X', '6X', '3c', '2c']
              //     let potenciesData = this.potencies
              //     this.potencies = potenciesData.filter(
              //         function(potenciesData){ return levels.includes(potenciesData.level) !== true }
              //     )
              // }

              this.price = 'from £' + this.sizes[0].price
            },
            chooseSize() {

              if (this.product.size) {
                let sizesData = this.sizes
                let size = this.product.size
                let sizesFiltered = sizesData.filter(
                    function(sizesData){ return sizesData.id === size }
                )[0];

                this.price = '£' + sizesFiltered.price
                console.log(this.price)
              }
            },
            onIntersect (entries, observer) {
              console.log(observer)
              // More information about these options
              // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
              //this.isIntersecting = entries[0].isIntersecting
            },
            scrollDrawer() {
              let offsetTop = this.basketDrawerContent.scrollTop
              let target = offsetTop + 200
              this.$vuetify.goTo(target, {container: this.basketDrawerContent})
            },
            isLoading (groupIndex) {
                if (this.loading === groupIndex) {
                    return true
                }
                else {
                    return false
                }
            },
            removeFromBasket(product, index) {

                return this.remove(product, index).then(() => {
                    console.log('removed')
                })
            },
            async clearBasket() {
                this.loading = true
                await basketService.clear()

                this.loading = false
                this.clearBasketModal = false
            },
            navigate(route, params) {
                this.$router.push({name: route, params: params}).catch(() => {})
            },
            navigateToCheckout() {
                if (this.$router.currentRoute.value.name === 'verify') {
                  //store.dispatch('alert/error', 'You must verify your email address to continue', { root: true });
                  this.$emit('close-drawer')
                }
                else if (this.$router.currentRoute.value.name !== 'checkout') {
                    router.push('/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.checkout')).catch(() => {})
                }
                else {
                    this.$emit('close-drawer')
                }

            },
            decrement (groupIndex) {
                this.loading = groupIndex

                let product = this.productGroups[groupIndex][0]
                let quantity = this.productGroups[groupIndex][0].quantity - 1

                return this.update({product, quantity}).then(() => {
                    this.loading = null
                })
            },
            increment (groupIndex) {
                //this.loading = groupIndex

                let product = this.productGroups[groupIndex][0]
                let quantity = this.productGroups[groupIndex][0].quantity + 1

                return this.update({product, quantity}).then(() => {
                    this.loading = null
                })
            },
            addFoilWrap() {
                this.setFoilWrapped(true).then(() => {
                    this.foilWrapDialog = false
                })
            },
            removeFoilWrap() {
                this.setFoilWrapped(false).then(() => {
                    this.foilWrapDialog = false
                })
            }
        }
    }
</script>

<style scoped>

</style>
