import cs from './cs.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fi from './fi.json'
import fr from './fr.json'
import it from './it.json'
import ja from './ja.json'
import nl from './nl.json'
import ru from './ru.json'

export const languages = {
    cs: cs,
    de: de,
    en: en,
    es: es,
    fi: fi,
    fr: fr,
    it: it,
    ja: ja,
    nl: nl,
    ru: ru
}
