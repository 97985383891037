import { defineStore } from 'pinia'

export const useAlertStore = defineStore('alert', {
    state: () => ({
        type: null,
        color: null,
        message: null,
        isReload: false,
        dismissible: false,
        show: false,
        alerts: []
    }),
    actions: {
        success(message) {
            this.type = 'success';
            this.color = 'success';
            this.message = message;
            this.isReload = false;
            this.dismissible = false;
            this.show = true;
        },
        error(message) {
            this.type = 'error';
            this.color = 'error';
            this.message = message;
            this.isReload = false;
            this.dismissible = false;
            this.show = true;
        },
        info(message) {
            this.type = 'info';
            this.color = 'info';
            this.message = message;
            this.isReload = false;
            this.dismissible = false;
            this.show = true;
        },
        warning(message) {
            this.type = 'warning';
            this.color = 'warning';
            this.message = message;
            this.isReload = false;
            this.dismissible = false;
            this.show = true;
        },
        reload(message) {
            this.type = 'info';
            this.color = 'info';
            this.message = message;
            this.isReload = true;
            this.dismissible = true;
            this.show = true;
        },
        clear() {
            this.type = null;
            this.color = null;
            this.message = null;
            this.isReload = false;
            this.dismissible = false;
            this.show = false;
        },
        setAlerts(alerts) {
            this.alerts = alerts
        },
        clearAlert(key) {
            this.alerts.splice(key, 1)
        },
        clearAlerts() {
            this.alerts = []
        }
    },
})
