import axios from "axios";
//import { store } from '../store';
import {basketService} from "./basket-service";
import {useIndexStore} from "../stores";
import {useBasketStore} from "../stores/basket"
import {useAccountStore} from "../stores/account";

export const userService = {
    login,
    logout,
    register,
    enableTwoFactor,
    disableTwoFactor,
    confirmPassword,
    getTwoFactorQR,
    twoFactorChallenge,
    twoFactorChallengeRecovery,
    getRecoveryCodes,
    regenerateRecoveryCodes,
    getMe
};

async function login(email, password) {

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {
        return apiClient
            .post('/login', {
                email: email,
                password: password
            })
            .then(async response => {
                //login successful
                if (response.status === 200 && response.data) {

                    if (response.data.two_factor) {
                        return response.data
                    } else {
                        let locale = response.data.data.preferred_language ? response.data.data.preferred_language : 'en'
                        let indexStore = useIndexStore()
                        await indexStore.setLocale(locale)
                        await indexStore.loggedIn()
                        let basketStore = useBasketStore()

                        basketService.sync().then(async response => {
                            if (response.data && response.data.basket_items.length > 0) {
                                let message = response.meta.message
                                basketStore.setBasketPopover({message}).then(() => {
                                    basketStore.calculateGroupDiscounts().then(() => {
                                        setTimeout(function () {
                                            let store = useBasketStore()
                                            store.$patch({popover: false, popoverMessage: ''})
                                        }, 3000)
                                    })
                                })
                            } else {
                                await basketStore.calculateGroupDiscounts()

                                return Promise.resolve(true)
                            }
                        })
                    }
                }

                return response.data.data;
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 422) {
                        return Promise.reject('incorrect email or password');
                    }
                    else if (error.response.status === 422) {
                        // auto logout if 401 response returned from api
                        logout();
                        return Promise.reject('incorrect email or password');
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    return Promise.reject(error);
                } else if (error.message) {
                    // Something happened in setting up the request that triggered an Error
                    console.log(error.message);
                    return Promise.reject(error);
                } else {
                    // The login was rejected due to incorrect role
                    return Promise.reject(error);
                }
            });
    })


}

async function logout() {

    useIndexStore().loggedOut()
    useAccountStore().logout()

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {
        return apiClient
            .post('/logout')
            .then(async response => {
                console.log(response.status)
                // logout successful
                if (response.status === 204 || response.status === 401) {

                    await useBasketStore().clear()

                    return Promise.resolve()
                }


            })
            .catch(function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    })
}

function register(email, password, title, first_name, last_name, address1, address2, town, county, postcode, country, phone, mobile, language, newsletter, vat_number, recaptchaToken, password_confirmation) {

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {
        return apiClient
            .post(import.meta.env.VITE_API_HOST + '/register', {
                email: email,
                password: password,
                password_confirmation: password_confirmation,
                title: title,
                first_name: first_name,
                last_name: last_name,
                address1: address1,
                address2: address2,
                town: town,
                county: county,
                postcode: postcode,
                country: country,
                language: language,
                newsletter: newsletter,
                phone: phone,
                mobile: mobile,
                vat_number: vat_number,
                recaptcha_token: recaptchaToken
            })
            .then(response => {

                // registration successful
                if (response.status === 201 && response.data) {
                    let indexStore = useIndexStore()
                    indexStore.loggedIn()
                }
                else {
                    return Promise.reject('not authorised');
                }

                return response.data.data;
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    if (error.response.status === 422) {

                        // store server side validation errors
                        // return store.dispatch('validationErrors', error.response.data.errors, {root: true}).then(response => {
                        //     console.log(response)
                        //     return Promise.reject('check form for errors');
                        // });
                        return Promise.reject(error);

                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else if (error.message) {
                    // Something happened in setting up the request that triggered an Error
                    console.log(error.message);
                } else {
                    // The login was rejected due to incorrect role
                    return Promise.reject(error);
                }
            });
    })

}

async function getMe() {

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .get(import.meta.env.VITE_API_HOST + '/api/users/me')
        .then(async response => {
            if (response.status === 200) {
                return Promise.reject('not authorised');
            }


        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function confirmPassword(password) {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .post(import.meta.env.VITE_API_HOST + '/user/confirm-password', {
            "password": password
        })
        .then(response => {

            if (response.status !== 201) {
                return Promise.reject('not authorised');
            }

            return response.data.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function enableTwoFactor() {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .post(import.meta.env.VITE_API_HOST + '/user/two-factor-authentication')
        .then(response => {

            if (response.status === 423) {
                return Promise.reject('locked');
            }
            else if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function disableTwoFactor() {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .delete(import.meta.env.VITE_API_HOST + '/user/two-factor-authentication')
        .then(response => {

            if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function getTwoFactorQR() {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .get(import.meta.env.VITE_API_HOST + '/user/two-factor-qr-code')
        .then(response => {

            if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function twoFactorChallenge(code) {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .post(import.meta.env.VITE_API_HOST + '/two-factor-challenge', {
            code: code
        })
        .then(response => {

            if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            let locale = response.data.preferred_language ? response.data.preferred_language : 'en'
            let indexStore = useIndexStore()
            indexStore.setLocale(locale)
            indexStore.loggedIn()
            let basketStore = useBasketStore()

            basketService.sync().then(response => {
                if (response.data.basket_items.length > 0) {
                    let message = response.meta.message
                    basketStore.setBasketPopover({message}).then(() => {
                        basketStore.calculateGroupDiscounts().then(() => {
                            setTimeout(function () {
                                let store = useBasketStore()
                                store.$patch({ popover: false, popoverMessage: '' })
                            }, 3000)
                        })
                    })
                }
                else {
                    basketStore.calculateGroupDiscounts().then(() => {
                        return Promise.resolve(true)
                    })
                }
            })

            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function twoFactorChallengeRecovery(code) {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .post(import.meta.env.VITE_API_HOST + '/two-factor-challenge', {
            recovery_code: code
        })
        .then(response => {

            if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            let indexStore = useIndexStore()
            indexStore.loggedIn()

            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function getRecoveryCodes() {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .get(import.meta.env.VITE_API_HOST + '/user/two-factor-recovery-codes')
        .then(response => {

            if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}

function regenerateRecoveryCodes() {
    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient
        .post(import.meta.env.VITE_API_HOST + '/user/two-factor-recovery-codes')
        .then(response => {

            if (response.status !== 200) {
                return Promise.reject('not authorised');
            }

            return response.data;


        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}
