import { defineStore } from 'pinia'
import {userService} from "../services";
//import {i18n} from "../i18n";
import { useAlertStore } from './alert'

export const useAccountStore = defineStore('account', {
    state: () => ({
        status: { loggedIn: false },
        user: {}
    }),
    persist: true,
    getters: {
        name: state => {
            return state.user ? state.user.name : null
        },
        twoFactorQR: state => {
            return state.user ? state.user.two_factor_qr: null
        },
        twoFactorRecoveryCodes: state => {
            return state.user ? state.user.two_factor_recovery_codes : null
        },
        isLoggedIn: state => {
            return state.status.loggedIn
        },
        userDiscountRate(state) {
            return state.user ? state.user.user_discount_rate : 0
        },
        usersName(state) {
            return state.user ? state.user.name : null
        },
        loggedIn(state) {
            return state.status.loggedIn
        },
    },
    actions: {
        login({ email, password }) {
            let that = this
            return userService.login(email, password)
                .then(response => {

                        if (response && response.two_factor) {
                            return response
                        }
                        else if (response) {
                            that.status = { loggedIn: true };
                            that.user = response;

                            return response
                        }
                    }

                ).catch(function (error) {
                    console.log(error)
                    that.status = { loggedIn: false };
                    that.user = {};
                    useAlertStore().error("Incorrect email or password")
                    return Promise.reject('incorrect email or password');
                });
        },
        logout() {
            this.status = { loggedIn: false };
            this.user = {};
        },
        register(email, password, title, first_name, last_name, address1, address2, town, county, postcode, country, phone, mobile, language, newsletter, vat_number, recaptchaToken, password_confirmation) {

            return userService.register(email, password, title, first_name, last_name, address1, address2, town, county, postcode, country, phone, mobile, language, newsletter, vat_number, recaptchaToken, password_confirmation)
                .then(
                    user => {
                        console.log(user)
                        this.status = { loggedIn: true };
                        this.user = user;

                        return user;
                    },
                    error => {
                        this.status = {};
                        this.user = { dependants: [] };

                        return Promise.reject(error);
                        //dispatch('alert/error', error, { root: true });
                    }
                );
        },
        updateMe() {
            let that = this
            return userService.getMe()
                .then(response => {
                        console.log(response)
                        if (response) {
                            that.user = response;

                            return response
                        }
                    }

                ).catch(function (error) {
                    console.log(error)
                });
        },
        updateUser(user) {
            this.user = user;
        },
        enableTwoFactor() {
            return userService.enableTwoFactor()
                .then(() => {
                    console.log("success")
                        this.user.two_factor_enabled = true
                    }
                ).catch(error => {
                    console.log(error)
                    if (error.response && error.response.status && [401, 403, 419, 500].includes(error.response.status)) {
                        userService.logout().then(() => {
                            useAlertStore().error(this.$t('You have been logged out due to inactivity'))
                        })
                    }
                    else {
                        if (error.response && error.response.data && error.response.data.error) {
                            useAlertStore().error(error.response.data.error)
                        }
                        else {
                            useAlertStore().error("Two factor enable failed")
                        }
                    }

                    return Promise.reject('two factor enable failed');
                });

        },
        disableTwoFactor() {
            return userService.disableTwoFactor()
                .then(() => {
                        this.user.two_factor_enabled = false
                        this.user.two_factor_qr = null
                        this.user.two_factor_recovery_codes = []
                    }
                ).catch(error => {
                    if (error.response && error.response.status && [401, 403, 419, 500].includes(error.response.status)) {
                        userService.logout().then(() => {
                            useAlertStore().error(this.$t('You have been logged out due to inactivity'))
                        })
                    }
                    else {
                        if (error.response && error.response.data && error.response.data.error) {
                            useAlertStore().error(error.response.data.error)
                        }
                        else {
                            useAlertStore().error("Two factor enable failed")
                        }
                    }

                    return Promise.reject('two factor enable failed');
                });
        },
        setTwoFactorQR() {
            return userService.getTwoFactorQR()
                .then(response => {
                        this.user.two_factor_qr = response.svg;
                    }
                ).catch(function () {
                    useAlertStore().error("Set factor QR failed")
                    return Promise.reject('Set factor QR failed');
                });
        },
        twoFactorChallenge(code) {
            return userService.twoFactorChallenge(code)
                .then(response => {
                    this.status = { loggedIn: true };
                    this.user = response;

                    return response
                }).catch(function () {
                    return Promise.reject('Two factor challenge failed');
                });
        },
        twoFactorChallengeRecovery(code) {
            return userService.twoFactorChallengeRecovery(code)
                .then(response => {
                    this.status = { loggedIn: true };
                    this.user = response;
                }).catch(function () {
                    return Promise.reject('Two factor challenge recovery failed');
                });
        },
        setRecoveryCodes() {
            return userService.getRecoveryCodes()
                .then(response => {
                        this.user.two_factor_recovery_codes = response;
                    }
                ).catch(function () {
                    useAlertStore().error("Failed to get recovery codes")
                    return Promise.reject('Failed to get recovery codes');
                });
        },
        regenerateRecoveryCodes() {
            return userService.regenerateRecoveryCodes()
                .then(response => {
                        this.user.two_factor_recovery_codes = response;
                    }
                ).catch(function () {
                    useAlertStore().error("Failed to get recovery codes")
                    return Promise.reject('Failed to get recovery codes');
                });
        },
        updateVerifiedAt(verifiedAt) {
            this.user.email_verified_at = verifiedAt
        },
        updateEmailVerificationSentAt(sentAt) {
            this.user.email_verification_sent_at = sentAt
        },
        updateUserDiscountRate(userDiscountRate) {
            this.user.user_discount_rate = userDiscountRate
        }
    },
})
