import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { languages } from './locales'

export const SUPPORT_LOCALES = ['en', 'fr', 'cs', 'nl', 'de', 'es', 'it', 'ja', 'ru', 'fi']

export function setupI18n(options = { locale: 'en', fallbackLocale: 'en' }) {
    const i18n = createI18n({
        ...options,
        missing: (locale, key, vm) => {
            // Check if the key contains a dot
            if (key.includes('.')) {
                // Split the key by the first dot and return the second part
                return key.split('.').slice(1).join('.');
            }
            // Return the full key if no dot is found
            return key;
        }
    });
    setI18nLanguage(i18n, options.locale)
    // load current locale messages ready for router
    loadLocaleMessages(i18n, options.locale)
    // load other languages async
    loadAllLocaleMessages(i18n)
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector('html').setAttribute('lang', locale)
}

export function loadLocaleMessages(i18n, locale) {
    // console.log("loadLocaleMessages" + locale)
    // // load locale messages with dynamic import
    // const messages = await import(
    //     `./locales/${locale}.json`
    //     )
    //
    // // set locale and locale message
    // i18n.global.setLocaleMessage(locale, messages.default)
    //
    // return nextTick()

    const messages = Object.assign(languages)
    i18n.global.setLocaleMessage(locale, messages[locale])

    return true
}

export async function loadAllLocaleMessages(i18n) {

    for (let x in SUPPORT_LOCALES) {
        let locale = SUPPORT_LOCALES[x]

        const messages = await import(
            `./locales/${locale}.json`
            )

        i18n.global.setLocaleMessage(locale, messages.default)
    }

    return true
}
