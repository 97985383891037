import { defineStore } from 'pinia'

export const useSearchStore = defineStore('search', {
    state: () => ({
        term: '',
        lastTerm: '',
        lastSearch: false,
        searchSource: ''
    }),
    getters: {
    },
    actions: {
        async setTerm(term, source) {
            console.log("store set term")
            this.term = term
            this.lastTerm = term
            this.lastSearch = false
            this.searchSource = source
        },
        clearTerm() {
            console.log("store clear term")
            this.term = ''
        },
        clearLastTerm() {
            this.lastTerm = ''
        }
    }
})
