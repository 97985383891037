
import { useIndexStore } from '../stores';

export function localizationHeader() {

    const locale = useIndexStore().locale;

    // return authorization header with jwt token
    if (locale) {
        return { 'Accept': 'application/json', 'Accept-Language': locale };
    } else {
        return { 'Accept': 'application/json', 'Accept-Language': 'en' };
    }
}
