import { defineStore } from 'pinia'
import {basketService, userService} from "../services";
import * as Sentry from "@sentry/vue";
import { useAlertStore } from './alert'
import { useAccountStore } from './account'

const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

const groupByName = groupBy('name');

export const useBasketStore = defineStore('basket', {
    state: () => ({
        products: [],
        popover: false,
        popoverMessage: '',
        timeout: false,
        postage: {},
        groupDiscountTotal: 0,
        groupDiscounts: {},
        deliveryCountry: null,
        vatNumber: null,
        foilWrapped: false,
        foilWrapTotal: 0,
        //containsRemedies: false,
        purchase: {},
        cleaned: false,
        priceChanges: false,
        basketEdited: false,
        basketOpen: false
    }),
    persist: true,
    getters: {
        postageId (state){
            return state.postage.id ? state.postage.id : null
        },
        basketProducts (state) {
            return state.products
        },
        containsRemedies (state){

            let remedies = false
            let products = state.products

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy === true) {
                    remedies = true
                }
            }

            return remedies
        },
        containsCanBeFoilWrapped (state){

            let wrapable = false
            let products = state.products

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy === true || products[i].can_be_foil_wrapped === true) {
                    wrapable = true
                }
            }

            return wrapable
        },

        /**
         * Returns the number of remedies.
         *
         * @returns {number}
         */
        remediesCount(state){

            let count = 0
            let products = state.products

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy === true && products[i].quantity === 1) {
                    count++
                }
            }

            return count
        },
        productGroups(state) {
            let products = state.products

            // we need the basket index added to the product so we can use it for removing from basket
            for (let i = 0; i < products.length; i++) {
                products[i]['basketIndex'] = parseInt(i)
            }

            return groupByName(products);
        },
        discountGroupIds(state){
            let groupDiscounts = state.groupDiscounts
            let ids = [];
            for (let i in groupDiscounts) {
                ids.push(parseInt(i))
            }
            return ids
        },
        productsCount(state){
            let count = 0
            let products = state.products
            for (let i = 0; i < products.length; i++) {
                count += products[i].quantity ? products[i].quantity : 1
            }

            return count
        },

        /**
         * Returns the products total.
         *
         * @returns {number}
         */
        productsTotal(state){
            let products = state.products;
            let total = 0;
            for (let i = 0; i < products.length; i++) {
                if (products[i] === undefined) {
                    Sentry.setContext("productUndefined", {
                        products: JSON.stringify(products)
                    });
                    let accountStore = useAccountStore();
                    Sentry.setUser({ email: accountStore.user ? accountStore.user.email : 'unknown' });
                    Sentry.captureMessage("Undefined product detected.");
                }
                else {
                    let quantity = products[i].quantity ? products[i].quantity : 1;
                    let price = parseFloat(products[i].price);

                    if (isNaN(price)) {
                        // Handle error when price is invalid
                        Sentry.setContext("productsTotal", {
                            iteration: i,
                            productPrice: products[i].price,
                            product: JSON.stringify(products[i]),
                            products: JSON.stringify(products)
                        });
                        let accountStore = useAccountStore();
                        Sentry.setUser({ email: accountStore.user ? accountStore.user.email : 'unknown' });
                        Sentry.captureMessage("Invalid product price detected.");
                    } else {
                        total += price * quantity;
                    }
                }
            }

            return parseFloat(total.toFixed(2));
        },
        grandTotal(state){

            let total = this.productsTotal - state.groupDiscountTotal - this.userDiscount - this.bulkDiscount.discount + state.foilWrapTotal

            return parseFloat(total.toFixed(2))
        },
        getBySlug (state) {
            return (slug) => state.products.find((product) => product.slug === slug)
        },
        productsWeight(state){
            let weight = 0
            let products = state.products
            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy) {
                    if (products[i].size && !isNaN(parseFloat(products[i].size.weight))) {
                        weight += parseFloat(products[i].size.weight);
                    }
                    else {
                        Sentry.setContext("productsWeight Remedy", {
                            product: products[i].id,
                            weight: products[i].size ? products[i].size.weight : 'unknown',
                            is_nan: isNaN(parseFloat(products[i].size.weight)),
                            basket_item_id: products[i].basket_item_id
                        });
                        let accountStore = useAccountStore()
                        Sentry.setUser({ email: accountStore.user ? accountStore.user.email : 'unknown' });
                        Sentry.captureMessage("There was an error calculating products weight");
                    }
                }
                else if (products[i].weight !== null && !isNaN(parseFloat(products[i].weight))) {
                    weight += (parseFloat(products[i].weight) * products[i].quantity)
                }
                else {
                    Sentry.setContext("productsWeight Product", {
                        product: products[i].id,
                        weight: products[i].weight,
                        is_nan: isNaN(parseFloat(products[i].weight)),
                        basket_item_id: products[i].basket_item_id
                    });
                    let accountStore = useAccountStore()
                    Sentry.setUser({ email: accountStore.user ? accountStore.user.email : 'unknown' });
                    Sentry.captureMessage("There was an error calculating products weight");
                }
            }
            return parseFloat(weight.toFixed(2))
        },
        kitsWeight(state){
            let weight = 0
            let products = state.products
            for (let i = 0; i < products.length; i++) {
                if (products[i].weight && products[i].is_kit) {
                    weight += (parseFloat(products[i].weight) * products[i].quantity)
                }
            }
            return parseFloat(weight.toFixed(2))
        },
        kitsTotal (state){
            let total = 0
            let products = state.products
            for (let i = 0; i < products.length; i++) {
                if (products[i].price && products[i].is_kit) {
                    total += (parseFloat(products[i].price) * products[i].quantity)
                }
            }
            return parseFloat(total.toFixed(2))
        },
        kitsCount (state){
            let count = 0
            let products = state.products
            for (let i = 0; i < products.length; i++) {
                if (products[i].is_kit) {
                    count += (1 * products[i].quantity)
                }
            }
            return count
        },
        getPostage(state) {
            return state.postage
        },
        getPurchase(state) {
            return state.purchase
        },
        calculatedTotal(state) {
            // return 2000 // Do not honour
            let subTotal = this.productsTotal
            let postage = this.getPostage
            let postageTotal = 0
            if (postage.total > 0) {
                postageTotal = parseFloat(postage.total)
            }
            let userDiscount = this.userDiscount ? this.userDiscount : 0
            let bulkDiscount = this.bulkDiscount ? this.bulkDiscount : { discount: 0 }

            let groupDiscountTotal = state.groupDiscountTotal ? state.groupDiscountTotal : 0
            let vatDeducted = this.vatDeducted ? this.vatDeducted : 0
            let foilWrapTotal = state.foilWrapTotal ? state.foilWrapTotal : 0
            let total = parseFloat((subTotal + postageTotal - userDiscount - bulkDiscount.discount - groupDiscountTotal - vatDeducted + foilWrapTotal).toFixed(2))
            if (isNaN(total)) {
                Sentry.setContext("calculateTotal", {
                    subTotal: subTotal,
                    postageTotal: postageTotal,
                    userDiscount: userDiscount,
                    bulkDiscount: bulkDiscount.discount,
                    groupDiscountTotal: groupDiscountTotal,
                    vatDeducted: vatDeducted,
                    foilWrapTotal: foilWrapTotal,
                    products: JSON.stringify(state.products)

                });
                let accountStore = useAccountStore()
                Sentry.setUser({ email: accountStore.user ? accountStore.user.email : 'unknown' });
                Sentry.captureMessage("There was an error calculating total");
            }
            return total
        },
        userDiscount (state) {
            console.log("userDiscount")
            let products = state.products
            let total = 0
            let accountStore = useAccountStore()
            let userDiscountRate = accountStore.user.user_discount_rate ? accountStore.user.user_discount_rate : 0
            let vatableCountries = import.meta.env.VITE_VATABLE_COUNTRIES.split(",")

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy) {

                    if (!products[i].exclude_from_discounts) {
                        let formId = products[i].form.id
                        let quantity = products[i].size.quantity

                        let discountableForms = import.meta.env.VITE_DISCOUNTABLE_FORMS.split(",").map(Number)
                        let disallowedQuantities = import.meta.env.VITE_DISALLOWED_QUANTITIES.split(",")

                        if (discountableForms.includes(formId)) {
                            if ((formId === 11 && disallowedQuantities.includes(quantity) !== true) || formId !== 11) {
                                let price = products[i].price * products[i].quantity
                                // take vat off remedy price for non vat applicable countries, before applying bulk discount
                                if (state.deliveryCountry && vatableCountries.includes(state.deliveryCountry) === false) {
                                    price = (price / 1.2)
                                }
                                total += price
                            }
                        }
                    }
                }
                else if (products[i].discountable) {
                    let price = products[i].price * products[i].quantity
                    if (state.deliveryCountry && vatableCountries.includes(state.deliveryCountry) === false) {
                        price = (price / 1.2)
                    }
                    total += price
                }
            }

            let discount = 0

            if (userDiscountRate > 0 && total > 0) {
                discount = total * (userDiscountRate / 100)
            }

            return parseFloat(discount.toFixed(2))
        },
        bulkDiscount (state) {

            let products = state.products
            let remedyTotal = 0

            let accountStore = useAccountStore()
            let userDiscountRate = accountStore.user.user_discount_rate ? accountStore.user.user_discount_rate : 0

            if (userDiscountRate > 0) {
                return {discount: 0, rate: 0}
            }

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy) {
                    if (!products[i].exclude_from_discounts) {
                        let formId = products[i].form.id
                        let quantity = products[i].size.quantity

                        let discountableForms = import.meta.env.VITE_DISCOUNTABLE_FORMS.split(",").map(Number)
                        let disallowedQuantities = import.meta.env.VITE_DISALLOWED_QUANTITIES.split(",")

                        if (discountableForms.includes(formId)) {
                            if ((formId === 11 && disallowedQuantities.includes(quantity) !== true) || formId !== 11) {
                                let price = products[i].price * products[i].quantity
                                // take vat off remedy price for non vat applicable countries, before applying bulk discount
                                let vatableCountries = import.meta.env.VITE_VATABLE_COUNTRIES.split(",")
                                if (state.deliveryCountry && vatableCountries.includes(state.deliveryCountry) === false) {
                                    price = (price / 1.2)
                                }
                                remedyTotal += price
                            }
                        }
                    }
                }
            }

            let discount = 0
            let rate = 0

            if (remedyTotal >= 450) {
                discount = remedyTotal * 0.15
                rate = 15
            }
            else if (remedyTotal >= 300) {
                discount = remedyTotal * 0.10
                rate = 10
            }
            else if (remedyTotal >= 175) {
                discount = remedyTotal * 0.05
                rate = 5
            }

            return {discount: parseFloat(discount.toFixed(2)), rate: rate}
        },
        totalForVat (state) {

            let products = state.products

            let postage = state.postage

            let postageTotal = postage.total ? parseFloat(postage.total) : 0

            // let userDiscount = this.userDiscount
            //
            // let bulkDiscount = this.bulkDiscount
            //
            // let groupDiscountTotal = state.groupDiscountTotal

            let foilWrapTotal = state.foilWrapTotal

            // let vatableTotal = foilWrapTotal + postageTotal - userDiscount - bulkDiscount.discount - groupDiscountTotal
            let vatableTotal = foilWrapTotal + postageTotal

            for (let i = 0; i < products.length; i++) {

                if (products[i].vat_rate && products[i].vat_rate === 20) {
                    if (products[i].is_remedy) {
                        vatableTotal += parseFloat(products[i].size.price * products[i].quantity)
                    }
                    else {
                        vatableTotal += parseFloat(products[i].price * products[i].quantity)
                    }
                }
            }

            return parseFloat(vatableTotal.toFixed(2))

        },
        vatDeducted (state) {

            let vat = 0

            let vatableCountries = import.meta.env.VITE_VATABLE_COUNTRIES.split(",")

            if (state.deliveryCountry && vatableCountries.includes(state.deliveryCountry) === false) {
                let vatableTotal = this.totalForVat

                if (vatableTotal > 0) {
                    vat = vatableTotal - (vatableTotal / 1.2)
                }
            }

            return parseFloat(vat.toFixed(2))
        },
        getFoilWrapTotal (state) {
            return state.foilWrapTotal
        },
        isOverWeightLimitForEU (state) {
            let weight = this.productsWeight
            let euCountries = import.meta.env.VITE_EU_COUNTRIES
            let limit = parseFloat(import.meta.env.VITE_WEIGHT_LIMIT)
            return (euCountries.includes(state.deliveryCountry) && weight > limit)
        },
        isOverWeightLimit (state) {
            let weight = this.productsWeight
            let countries = import.meta.env.VITE_WEIGHT_LIMIT_COUNTRIES
            let limit = parseFloat(import.meta.env.VITE_WEIGHT_LIMIT)
            return (countries.includes(state.deliveryCountry) && weight > limit || weight > 30000)
        },

        /**
         * Returns boolean is over remedy limit.
         *
         * @returns {boolean}
         */
        isOverRemedyLimit (state) {
            let remediesCount = state.remediesCount
            let countries = import.meta.env.VITE_REMEDY_LIMIT_COUNTRIES
            let limit = parseInt(import.meta.env.VITE_REMEDY_LIMIT)

            return (countries.includes(state.deliveryCountry) && remediesCount > limit)
        },

        /**
         * Returns boolean is over price limit for EU.
         *
         * @returns {boolean}
         */
        isOverPriceLimitForEU (state) {
            let total = this.productsTotal
            let countries = import.meta.env.VITE_EU_COUNTRIES
            let limit = parseFloat(import.meta.env.VITE_EU_PRICE_LIMIT)
            return (countries.includes(state.deliveryCountry) && total > limit)
        },
        isDeliveryToRestrictedCountry (state) {
            let countries = import.meta.env.VITE_RESTRICTED_COUNTRIES
            return countries.includes(state.deliveryCountry)
        },
        restrictedProducts (state) {
            let products = state.products

            let restrictedProducts = ''

            for (let i = 0; i < products.length; i++) {

                if (products[i].only_to && products[i].only_to.length > 0) {
                    if (products[i].only_to && !products[i].only_to.includes(state.deliveryCountry)) {
                        if (restrictedProducts !== '') {
                            restrictedProducts += ', '
                        }

                        restrictedProducts += products[i].name
                    }
                }
                else if (products[i].restricted_from && products[i].restricted_from.includes(state.deliveryCountry)) {
                    if (restrictedProducts !== '') {
                        restrictedProducts += ', '
                    }

                    restrictedProducts += products[i].name
                }
            }

            return restrictedProducts
        },
        restrictedFormsContainingAlcohol (state) {
            let products = state.products

            let restrictions = ''

            let restrictedCountries = import.meta.env.VITE_ALCOHOL_RESTRICTED_COUNTRIES.split(",")
            let restrictedForms = import.meta.env.VITE_FORMS_CONTAINING_ALCOHOL.split(",")

            if (restrictedCountries.includes(state.deliveryCountry)) {

                for (let i = 0; i < products.length; i++) {

                    if (products[i].is_remedy && products[i].form.id && restrictedForms.includes(products[i].form.id.toString())) {

                        if (restrictions !== '') {
                            restrictions += ', '
                        }

                        restrictions += products[i].name + ': ' + products[i].form.name
                    }
                    else if (!products[i].is_remedy && products[i].contains_alcohol) {

                        if (restrictions !== '') {
                            restrictions += ', '
                        }

                        restrictions += products[i].name
                    }
                }
            }

            return restrictions
        }
    },
    actions: {
        setBasketOpen() {
            this.basketOpen = true
        },
        setBasketClosed() {
            this.basketOpen = false
        },
        resetCleaned() {
            this.cleaned = false
        },
        resetPriceChanges() {
            this.priceChanges = false
        },
        resetBasketEdited() {
            this.basketEdited = false
        },
        async setProducts(data) {
            this.products = []

            for (let x in data) {

                this.products.push({
                    basket_item_id: data[x].id,
                    id: data[x].product.id,
                    name: data[x].product.name,
                    slug: data[x].product.slug,
                    path: data[x].product.path,
                    price: data[x].product.price ? parseFloat(data[x].product.price) : parseFloat(data[x].size.price),
                    form: data[x].form,
                    size: data[x].size,
                    potency: data[x].potency,
                    quantity: parseInt(data[x].quantity),
                    weight: data[x].product.weight,
                    is_kit: data[x].product.is_kit,
                    is_remedy: data[x].product.is_remedy,
                    exclude_from_discounts: data[x].product.exclude_from_discounts,
                    exclude_from_discounts_in_uk: data[x].product.exclude_from_discounts_in_uk,
                    can_be_foil_wrapped: data[x].product.can_be_foil_wrapped,
                    discount_groups: data[x].product.discount_groups,
                    vat_rate: data[x].product.vat_rate,
                    restricted_from: data[x].product.restricted_from,
                    only_to: data[x].product.only_to,
                    discountable: data[x].product.discountable
                })
            }
        },
        async clean({products, sizes}) {
            await this.cleanProducts({products, sizes})
            await basketService.sync().catch(error => {

                // Sentry.captureException(error);

                if (error.response && error.response.status && [401, 403, 419].includes(error.response.status)) {
                    userService.logout().then(() => {
                        useAlertStore().error(translate('You have been logged out due to inactivity'))
                    })
                }
                else {
                    if (error.response && error.response.data && error.response.data.error) {
                        useAlertStore().error(error.response.data.error)
                    }
                    else {
                        useAlertStore().error('Unknown error occurred')
                    }
                }
            })
            await this.calculateGroupDiscounts().catch(error => {
                Sentry.captureException(error);
            })
            await this.calculateFoilWrap().catch(error => {
                Sentry.captureException(error);
            })

        },
        async cleanProducts({products, sizes}) {
            this.cleaned = false
            for (let x in this.products) {
                if (products.includes(this.products[x].id)) {
                    this.products.splice(x,1);
                    this.cleaned = true
                }
                else if (this.products[x].is_remedy && sizes.includes(this.products[x].size.id)) {
                    this.products.splice(x,1);
                    this.cleaned = true
                }
            }
        },
        async cleanPrices({products, sizes}) {
            await this.cleanProductPrices({products, sizes})
            await basketService.sync()
            await this.calculateGroupDiscounts()
            await this.calculateFoilWrap()
        },
        async updateGroupDiscounts({discountGroups}) {

            let productsUpdated = false;
            // each discount group returned
            for (const [key, discountGroup] of Object.entries(discountGroups)) {

                // check for products removed from discount group
                for (let x in discountGroup.products_missing) {
                    let removedProduct = discountGroup.products_missing[x]

                    for (let x = 0; x < this.products.length; x++) {
                        let product = this.products[x]

                        if (removedProduct === product.id) {
                            for (let i in product.discount_groups) {
                                let currentDiscountGroup = product.discount_groups[i]
                                if (currentDiscountGroup.id === parseInt(key)) {
                                    this.products[x].discount_groups.splice(parseInt(i), 1)
                                    productsUpdated = true
                                }
                            }
                        }

                    }
                }

                // check for products part of a discount group that has been updated
                for (let x in discountGroup.products_updated) {
                    let updatedProduct = discountGroup.products_updated[x]
                    let updatedDiscountGroup = discountGroup.discount_group

                    for (let x = 0; x < this.products.length; x++) {
                        let product = this.products[x]

                        if (updatedProduct === product.id) {
                            for (let i in product.discount_groups) {
                                if (product.discount_groups.length > 0) {
                                    let currentDiscountGroup = product.discount_groups[i]
                                    if (currentDiscountGroup.id === parseInt(key)) {
                                        this.products[x].discount_groups[i] = updatedDiscountGroup
                                        productsUpdated = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (productsUpdated) {
                useAlertStore().warning(translate('Some products may no longer be eligible for discounts; please review your basket.'))
            }

            await basketService.sync()
            await this.calculateGroupDiscounts()
            await this.calculateFoilWrap()
        },
        async cleanProductPrices({products, sizes}) {
            this.priceChanges = false

            // this.products refers to products in the basket
            for (let x = 0; x < this.products.length; x++) {

                if (this.products[x].is_remedy) {

                    let sizeId = this.products[x].size.id

                    let filteredSizes = sizes.filter(function(item) {
                        return item.id === sizeId
                    });

                    if (filteredSizes.length > 0) {
                        this.products[x].price = filteredSizes[0].price;
                        this.products[x].size.price = filteredSizes[0].price;
                        this.priceChanges = true

                    }
                }
                else {
                    let productId = this.products[x].id

                    let filteredProducts = products.filter(function(item) {
                        return item.id === productId
                    });

                    if (filteredProducts.length > 0) {
                        this.products[x].price = filteredProducts[0].price;
                        this.priceChanges = true
                    }
                }
            }
        },
        async add(data) {

            this.products.push({
                id: data.product.id,
                name: data.product.name,
                slug: data.product.slug,
                path: data.product.path,
                price: data.product.price ? parseFloat(data.product.price) : data.sizeModel.price,
                form: data.formModel,
                size: data.sizeModel,
                potency: data.potencyModel,
                quantity: 1,
                weight: data.product.weight,
                is_kit: data.product.is_kit,
                is_remedy: data.product.is_remedy,
                exclude_from_discounts: data.product.exclude_from_discounts,
                exclude_from_discounts_in_uk: data.product.exclude_from_discounts_in_uk,
                can_be_foil_wrapped: data.product.can_be_foil_wrapped,
                discount_groups: data.product.discount_groups,
                vat_rate: data.product.vat_rate,
                restricted_from: data.product.restricted_from,
                only_to: data.product.only_to,
                discountable: data.product.discountable
            })

            await basketService.sync().catch(error => {
                console.log(error)
                if (error.response) {
                    if ([401, 403, 419].includes(error.response.status)) {
                        userService.logout().then(() => {
                            useAlertStore().error(translate('You have been logged out due to inactivity'))
                        })
                        // this.$router.push({name: 'login'})
                    }
                }
            })
            await this.calculateGroupDiscounts()
            await this.calculateFoilWrap()


        },
        async addOnly(data) {

            this.products.push({
                id: data.product.id,
                name: data.product.name,
                slug: data.product.slug,
                path: data.product.path,
                price: data.product.price ? data.product.price : data.sizeModel.price,
                form: data.formModel,
                size: data.sizeModel,
                potency: data.potencyModel,
                quantity: 1,
                weight: data.product.weight,
                is_kit: data.product.is_kit,
                is_remedy: data.product.is_remedy,
                exclude_from_discounts: data.product.exclude_from_discounts,
                discount_groups: data.product.discount_groups,
                vat_rate: data.product.vat_rate,
                restricted_from: data.product.restricted_from,
                only_to: data.product.only_to,
                discountable: data.product.discountable
            })
        },
        async calculateGroupDiscounts() {

            this.groupDiscountTotal = 0
            this.groupDiscounts = {}

            let products = this.products

            //this.containsRemedies = false

            let discountGroups = {}

            for (let x = 0; x < products.length; x++) {

                let product = products[x]

                let exclude = false
                if (this.deliveryCountry === 'GB' && product.exclude_from_discounts_in_uk) {
                    exclude = true
                }

                if (product.discount_groups && product.discount_groups.length > 0) {
                    for (let d in product.discount_groups) {
                        //console.log(product.discount_groups[d])
                        let discountGroup = product.discount_groups[d]

                        if (exclude && discountGroup.discount >= 35) {
                            useAlertStore().warning(translate('Some discounts may not be eligible for your delivery country; please review your basket.'))
                        }

                        if (!exclude || (exclude && discountGroup.discount < 35)) {
                            if (!(discountGroup.id in discountGroups)) {
                                discountGroups[discountGroup.id] = []

                                discountGroups[discountGroup.id] = {
                                    name: discountGroup.name,
                                    qualifying_quantity: discountGroup.qualifying_quantity,
                                    product_ids: [],
                                    products_total: 0,
                                    discountable: 0,
                                    discount_percent: discountGroup.discount,
                                    discount_total: 0,
                                    products_count: discountGroup.products_count,
                                    slug: discountGroup.slug
                                }
                            }

                            discountGroups[discountGroup.id].product_ids.push(product.id)
                            discountGroups[discountGroup.id].products_total += product.quantity

                            let price = product.price
                            let vatableCountries = import.meta.env.VITE_VATABLE_COUNTRIES.split(",")
                            if (this.deliveryCountry && vatableCountries.includes(this.deliveryCountry) === false) {
                                price = (price / 1.2)
                            }

                            discountGroups[discountGroup.id].discountable += parseFloat((price * product.quantity).toFixed(2))
                        }

                    }
                }

                if (product.is_remedy === true) {
                    //this.containsRemedies = true
                }
            }

            let discountTotal = 0
            for (let id in discountGroups) {
                if (Object.prototype.hasOwnProperty.call(discountGroups, id)) {
                    let discountGroup = discountGroups[id]
                    if (discountGroup.products_total >= discountGroup.qualifying_quantity) {
                        let total = discountGroup.discountable * (discountGroup.discount_percent / 100)
                        discountTotal += total
                        discountGroup.discount_total = parseFloat(total.toFixed(2))
                    } else {
                        delete discountGroups[id]
                    }
                }
            }

            // if in more than one discount group, remove lowest
            for (let id in discountGroups) {
                if (Object.prototype.hasOwnProperty.call(discountGroups, id)) {
                    let discountGroup = discountGroups[id]
                    //let total = discountGroup.discount_total
                    let productIds = discountGroup.product_ids
                    let discountPercent = discountGroup.discount_percent
                    for (let id2 in discountGroups) {
                        if (Object.prototype.hasOwnProperty.call(discountGroups, id2)) {
                            let discountGroup2 = discountGroups[id2]

                            // if a different discount group
                            if (id2 !== id) {

                                // check if duplicate product id
                                for (let p in productIds) {
                                    let productId = productIds[p]

                                    if (discountGroup2.product_ids.includes(productId)) {

                                        // then compare discount percent
                                        if (discountGroup2.discount_percent < discountPercent) {
                                            discountGroup2.product_ids.splice(discountGroup2.product_ids.indexOf(productId), 1)

                                            for (let x = 0; x < products.length; x++) {
                                                let product = products[x]
                                                if (product.id === productId) {
                                                    discountGroup2.products_total -= product.quantity

                                                    // adjust the discount total
                                                    discountTotal -= (discountGroup2.discountable * (discountGroup2.discount_percent / 100))
                                                    // adjust the discountable total
                                                    discountGroup2.discountable -= parseFloat((product.price * product.quantity).toFixed(2))

                                                }
                                            }

                                            // recalculate the discount total
                                            if (discountGroup2.products_total >= discountGroup2.qualifying_quantity) {

                                                discountTotal += discountGroup2.discountable * (discountGroup2.discount_percent / 100)
                                                discountGroup2.discount_total = parseFloat(discountTotal.toFixed(2))
                                            } else {
                                                delete discountGroups[id2]
                                            }
                                        }
                                    }
                                }


                            }
                        }

                    }
                }

            }

            this.groupDiscounts = discountGroups
            this.groupDiscountTotal = parseFloat(discountTotal.toFixed(2))
        },
        async update(data) {

            this.cleaned = false

            this.popover = false
            this.popoverUpdate = false

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            for (let x = 0; x < this.products.length; x++) {
                if (this.products[x].id === data.product.id) {
                    if (data.quantity === 0) {
                        this.products.splice(Number(x),1);
                    }
                    else {
                        this.products[x].quantity = data.quantity
                    }
                }
            }

            this.popoverUpdate = true
            this.timeout = setTimeout(function () { this.popoverUpdate = false }, 3000)

            if(data.quantity === 0 && data.product.basket_item_id) {
                await basketService.destroy(data.product.basket_item_id)
                await this.calculateGroupDiscounts()

            }
            else if (data.product.basket_item_id) {
                await basketService.sync()
                await this.calculateGroupDiscounts()
            }
            else {
                await this.calculateGroupDiscounts()
            }

            this.basketEdited = true
        },
        async updateQuantity(product, quantity) {
            for (let x = 0; x < this.products.length; x++) {
                if (this.products[x].id === product.id) {
                    this.products[x].quantity = quantity
                    this.basketEdited = true
                }
            }
        },
        async updateRemedyOptions(data) {
            let remedy = this.products[data.product.basket_index]
            remedy.form = data.formModel
            remedy.size = data.sizeModel
            remedy.potency = data.potencyModel
            remedy.price = data.product.price ? data.product.price : data.sizeModel.price

            // await basketService.sync().catch(error => {
            //     console.log(error)
            //     if (error.response) {
            //         if ([401, 403, 419].includes(error.response.status)) {
            //             userService.logout().then(() => {
            //                 useAlertStore().error(translate('You have been logged out due to inactivity'))
            //             })
            //             // this.$router.push({name: 'login'})
            //         }
            //     }
            // })
            // await this.calculateGroupDiscounts()
            // await this.calculateFoilWrap()

            this.basketEdited = true
        },
        async remove(product, index) {

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.products.splice(index,1);
            //setTimeout(closeBasket, 5000)

            let remedies = false
            let products = this.products

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy === true) {
                    remedies = true
                }
            }

            if (this.products.length === 0 || remedies === false) {
                //this.containsRemedies = false
                this.foilWrapTotal = 0
                this.foilWrapped = false
            }

            let status = true
            let message = 'deleted'

            if (product.basket_item_id) {
                await basketService.destroy(product.basket_item_id)
            }

            await this.calculateGroupDiscounts()
            await this.calculateFoilWrap()

            // await this.setBasketPopover({status, message})
            // setTimeout(function () { this.resetBasketPopover() }, 3000)

            this.basketEdited = true
        },
        async clear() {
            // this.products = []
            // this.postage = {}
            // this.groupDiscountTotal = 0
            // this.groupDiscounts = {}
            // this.deliveryCountry = null
            // //this.containsRemedies = false
            // this.purchase = {}
            // this.foilWrapTotal = 0
            // this.foilWrapped = false
            this.$reset()

            await this.calculateGroupDiscounts()

            let status = true
            let message = 'cleared'

            return true

            // await this.setBasketPopover({status, message})
            // setTimeout(function () { this.resetBasketPopover() }, 3000)
        },
        setPostage(postage) {
            this.postage = {
                id: postage.id,
                name: postage.name,
                price: postage.postage_price.toFixed(2),
                surcharge: postage.surcharge.toFixed(2),
                total: (postage.postage_price + postage.surcharge).toFixed(2),
                message: postage.message
            }
        },
        clearPostage() {
            this.postage = {}
        },
        async setBasketPopover(data) {
            this.popover = true
            if (data.message === 'saved') {
                this.popoverMessage = translate('alerts.Basket saved')
            }
            else if (data.message === 'loaded') {
                this.popoverMessage = translate('alerts.Basket loaded')
            }
            else if (data.message === 'synced') {
                this.popoverMessage = translate('alerts.Basket synced')
            }
            else if (data.message === 'added') {
                this.popoverMessage = translate('alerts.Item added to basket')
            }
            else if (data.message === 'cleared') {
                this.popoverMessage = translate('alerts.Basket cleared')
            }
            else {
                this.popoverMessage = translate('alerts.Basket updated')
            }
        },
        resetBasketPopover() {
            this.popover = false
            this.popoverMessage = ''
        },
        setDeliveryCountry(country) {
            this.deliveryCountry = country
        },
        setVatNumber(vatNumber) {
            this.vatNumber = vatNumber
        },
        async setFoilWrapped(status) {

            if (status === true) {
                this.foilWrapped = true
                await this.calculateFoilWrap()
            }
            else {
                this.foilWrapped = false
                this.foilWrapTotal = 0
            }

        },
        async calculateFoilWrap() {
            let products = this.products
            let remedyCount = 0
            let multiplier = 0

            for (let i = 0; i < products.length; i++) {
                if (products[i].is_remedy || products[i].can_be_foil_wrapped) {
                    remedyCount++
                }
            }

            if (remedyCount === 0) {
                this.foilWrapTotal = 0
            }
            else if (this.foilWrapped) {
                if (remedyCount <= 6 ) {
                    multiplier = 3
                }
                else {
                    multiplier = 3 + (2 * Math.ceil((remedyCount - 6) / 5))
                }

                let total = multiplier

                this.foilWrapTotal = parseFloat(total);
            }
        },
        setPurchase(purchase) {
            this.purchase = purchase
        }
    }
})
