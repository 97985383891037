import axios from "axios";
import {useIndexStore} from "../stores";
import {useBasketStore} from "../stores/basket";
import {useAlertStore} from "../stores/alert";
import {useAccountStore} from "../stores/account";

export const basketService = {
    sync,
    clean,
    destroy,
    clear
};

async function sync() {

    if (useIndexStore().isAuthenticated === false) {
        return Promise.resolve(true)
    }

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    await apiClient.get('/sanctum/csrf-cookie');

    return apiClient
        .post('/api/users/me/basket', {
            'products': useBasketStore().products
        })
        .then(response => {
            let data = response.data

            if (data.meta.deleted) {
                useAlertStore().error(translate('Some items have been removed that are no longer available; please review your basket.'))
            }

            if (data.meta.price_changes) {
                useAlertStore().error(translate('Some prices have been updated; please review your basket.'))
            }

            if (data.meta.user_discount_rate) {
                useAccountStore().updateUserDiscountRate(data.meta.user_discount_rate)
            }

            useBasketStore().setProducts(response.data.data.basket_items)

            return data;
        })
        .catch(function (error) {
            console.log(error)
            return Promise.reject(error);
        });
}

function clean() {

    // if (store.getters['isAuthenticated'] === false) {
    //     return Promise.resolve(true)
    // }

    let basketStore = useBasketStore()
    basketStore.resetCleaned()
    basketStore.resetPriceChanges()

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {

        return apiClient
            .post('/api/products/clean-basket', {
                'products': basketStore.products,
                'group_discounts': basketStore.groupDiscounts
            })
            .then(response => {
                let data = response.data

                if ((data.products.length > 0 || data.sizes.length > 0) && data.deleted) {

                    let products = data.products
                    let sizes = data.sizes
                    basketStore.cleanProducts({products, sizes}).then(() => {
                        console.log("products cleaned")
                        useAlertStore().warning(translate('Some items have been removed that are no longer available; please review your basket.'))
                    });
                }

                if ((data.product_prices_changes.length > 0 || data.size_price_changes.length > 0) && data.price_changes) {
                    let products = data.product_prices_changes
                    let sizes = data.size_price_changes
                    basketStore.cleanPrices({products, sizes}).then(() => {
                        console.log("product prices cleaned")
                        useAlertStore().warning(translate('Some prices have been updated; please review your basket.'))
                    });
                }

                if (data.discount_group_product_changes && Object.keys(data.discount_group_product_changes).length > 0) {
                    let discountGroups = data.discount_group_product_changes
                    basketStore.updateGroupDiscounts({discountGroups}).then(() => {
                        //
                    });
                }

            })
            .catch(function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    });
}

function destroy(basketItemId) {

    if (!basketItemId) {
        return Promise.resolve(true)
    }

    let indexStore = useIndexStore()

    if (indexStore.isAuthenticated === false) {
        return Promise.resolve(true)
    }

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
        withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {
        return apiClient
            .delete('/api/users/me/basket/' + basketItemId)
            .then(response => {
                console.log(response.status)
            })
            .catch(function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    })
}

async function clear() {

    let basketStore = useBasketStore()

    await basketStore.clear()

    let indexStore = useIndexStore()

    if (indexStore.isAuthenticated === false) {
        return Promise.resolve(true)
    }

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
                    withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {
        return apiClient
            .delete('/api/users/me/basket')
            .then(response => {
                console.log(response.status)
            })
            .catch(function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    })
}
